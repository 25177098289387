class MyHeader extends HTMLElement {
	connectedCallback() {
		this.innerHTML =

		`
		<div class="container">
        <div class="logo-container">
            <div class="logo"><a href = "index.html">

            		<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 28.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="micro_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<g>
		<path class="st0" d="M46,36.4c-0.4-0.1-0.8-0.2-1.3-0.2c-0.7,0-1.3,0.2-1.9,0.4l-0.1,0c-0.9,0.3-1.7,0.1-2.5-0.5
			c-0.4-0.3-0.8-0.7-1.1-1.1c-0.6-0.6-1.1-1.1-1.8-1.4c-0.6-0.3-1.3-0.5-2.1-0.4c-0.7,0.1-1.2,0.6-1.4,1.4c-0.3,1.1-0.3,2.1,0.1,3
			c0.4,1,0.6,2.1,0.8,3.2c0.2,1.4,0.1,2.5-0.3,3.5c-0.4,1.1-0.8,2.3-1.1,3.4l-0.2,0.5c-0.2,0.6-0.3,1.2-0.2,1.8c0,1,0.7,1.7,1.6,1.7
			c0,0,0,0,0.1,0c0.7,0,1.1-0.4,1.2-1.1c0.1-0.6,0.1-1.2,0-1.6c-0.1-1.6-0.1-3,0.1-4.4c0.1-0.6,0.2-1,0.4-1.3
			c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.4,0.4-0.6c0.6-1,1.6-1.2,2.6-0.7l0,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-0.6,1-0.8,1.6
			c-0.4,1.2-0.5,2.5-0.6,3.8c0,0.6,0.2,1.2,0.7,1.7c0.5,0.5,1.2,0.7,1.8,0.5c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2
			c0.2-0.2,0.5-0.4,0.6-0.7c0.5-0.7,0.4-1.5-0.2-2.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.5-0.7-1-0.8-1.7
			c-0.1-0.5,0-1,0.3-1.3c0.3-0.3,0.7-0.5,1.2-0.5c0.2,0,0.4,0,0.5,0.1c0.3,0,0.6,0.1,1,0.1l0.1,0c0.3,0,0.7,0,1-0.1
			c1.9-0.8,2.9-2.6,2.5-4.8C46.9,37.1,46.6,36.5,46,36.4z M35.7,37.2c0-0.6,0.2-1,0.8-1.3c0.3-0.1,0.6-0.1,0.8,0.1l0.1,0.1
			c0.3,0.3,0.6,0.6,0.8,1c0.7,1,1.5,1.4,2.6,1.3l0.3,0c0.3,0,0.7-0.1,1.1-0.1c0.2,0,0.4,0,0.6,0c0.3,0.1,0.6,0.3,0.7,0.7
			c0.1,0.4,0,0.7-0.2,1c-0.4,0.4-0.8,0.5-1.3,0.3c-0.8-0.2-1.6-0.5-2.5-0.8c-0.2-0.1-0.5-0.1-0.7-0.1c-0.4,0-0.7,0.1-1.1,0.4
			c-0.7,0.5-0.9,0.4-1.4-0.3C35.8,38.7,35.6,38,35.7,37.2L35.7,37.2z"/>
		<path class="st0" d="M19.3,43.8L19.3,43.8c-0.6-0.2-1.1-0.4-1.6-0.7c-0.2-0.1-0.3-0.3-0.4-0.5c0-0.3,0-0.6,0.2-0.8
			c0.2-0.3,0.5-0.5,0.9-0.4c0.2,0,0.3,0,0.5,0.1c0.1,0,0.2,0,0.3,0.1c0.6,0.1,1-0.3,1.1-0.9c0.1-0.8-0.1-1.6-0.6-2.2
			c-0.4-0.4-0.9-0.7-1.3-1l-0.3-0.1c-1.1-0.6-1.7-1.4-1.8-2.5c-0.1-0.7-0.3-1.4-0.7-2.2c-0.3-0.5-0.7-0.8-1.2-1
			c-0.8-0.2-1.5,0-2.3,0.6c-0.4,0.4-0.7,0.9-0.7,1.5c-0.1,0.8,0.1,1.5,0.4,2.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.3,0.3,0.6,0.4,0.9
			c0.1,0.3,0.2,0.7,0.2,1c0.1,0.9-0.2,1.7-0.8,2.5c-0.6,0.8-1.4,1.2-2.2,1.6C8,43.1,7.2,45,7.6,47c0.2,0.8,0.7,1.4,1.5,1.7
			c0.7,0.3,1.5,0.2,2.4-0.1l0.2-0.1c0.3-0.1,0.6-0.2,0.9-0.3c1-0.2,2.1-0.1,3.1,0.3l2.5,1l0.1,0l0,0c0,0,0.1,0,0.1,0
			c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.6,0,1.1-0.2,1.5-0.7c0.5-0.5,0.7-1.3,0.7-2.2c0-0.8-0.3-1.6-0.8-2.1
			C20.2,44.3,19.8,44,19.3,43.8z M16.7,47.3L16.7,47.3c-0.7-0.6-1.4-1.1-2.2-1.4c-0.7-0.3-1.7-0.4-2.5,0.5c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.3,0.2-0.7,0.2-1.1,0c-0.3-0.2-0.5-0.6-0.6-1c0-0.2,0-0.4,0.1-0.5c0.5-0.9,1.1-1.4,1.9-1.6c0.4-0.1,0.7,0,1,0.2
			c0.5,0.4,1.1,0.6,1.8,0.5c0.2,0,0.3,0,0.5,0c0.3,0,0.7-0.1,1-0.1c0,0,0,0,0,0c1.8,0,2.6,1.4,2.6,2.8c0,0.3-0.1,0.5-0.2,0.7
			C18.5,48,18.2,48,17.8,48c-0.1-0.1-0.2-0.1-0.3-0.2C17.2,47.6,16.9,47.4,16.7,47.3z M15.9,40.7c-0.1,0.2-0.3,0.5-0.5,0.7
			c-0.2,0.3-0.5,0.4-0.9,0.2c-0.4-0.1-0.6-0.4-0.6-0.8c0-0.3,0.1-0.6,0.1-0.9c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.1,0.1-0.2
			c0.4-0.9,0.3-1.9-0.1-2.8c-0.2-0.3-0.3-0.6-0.4-1c-0.1-0.3-0.1-0.5,0-0.6c0.1-0.2,0.3-0.3,0.6-0.3c0.3,0.1,0.4,0.4,0.4,0.8
			c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.2,0.5,0.3,0.8c0.2,0.5,0.6,0.8,1.1,0.9c0.3,0.1,0.5,0.1,0.9,0.3
			c0.1,0,0.2,0.1,0.3,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0.1-0.6,0.2C16.4,40,16.1,40.2,15.9,40.7z"/>
		<path class="st0" d="M33.3,39.3c-0.2-0.3-0.6-0.5-0.9-0.5c-0.4,0-0.7,0.1-1,0.4c-0.2,0.2-0.3,0.4-0.5,0.6
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.4-0.6,0.4-1,0.2c-0.7-0.5-0.8-1-0.4-1.8c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.8,1-1.2
			c0.8-1.1,1.3-2.1,1.6-3.2c0.2-0.9,0.2-1.7,0-2.5c-0.4-1.6-1.4-2.8-3.1-3.8c-0.1-0.1-0.2-0.1-0.3-0.1l-0.1,0c-0.6-0.1-1.3-0.1-2,0
			c-1.1,0.2-2,0.8-2.6,1.8c-0.6,1-0.9,2.1-1.1,3l0,0c0,0.2,0,0.4,0,0.5c0,0.4-0.1,0.8-0.1,1.2c0,0.7,0.3,1.2,0.9,1.6
			c0.5,0.3,1,0.4,1.5,0.3c0.6-0.1,1-0.4,1.3-1c0.2-0.6,0.2-1.2,0.2-1.8c0-0.2,0-0.3,0-0.5c0-0.4-0.1-0.7,0-1.1
			c0.1-0.6,0.4-0.8,0.9-1c0.5-0.1,1,0,1.6,0.2c0.9,0.4,1.2,1.2,1,2.2c-0.3,1.5-1,2.5-2.1,3.1c-0.8,0.4-1.6,0.8-2.4,1.1l-0.7,0.3
			c-0.6,0.2-1.1,0.5-1.6,0.9c-0.7,0.6-1.4,1.4-1.6,2.7c-0.2,1.5,0.2,2.9,1.4,4.1c0.2,0.2,0.6,0.4,0.9,0.5c0.3,0.1,0.5,0.1,0.8,0.1
			c0.4,0,0.8-0.1,1.3-0.3c0.9-0.4,1.6-0.8,2.1-1.5c0.7-0.9,1.5-1.3,2.4-1.3c0,0,0.1,0,0.1,0c0.6,0,1.3-0.2,2-0.6
			c0.8-0.5,1.1-1.2,1-2.2C33.6,39.8,33.5,39.5,33.3,39.3z M28.1,41.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.9,0.3
			c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.5-0.3-0.9-0.3-1.4,0l-0.1,0c0,0-0.1,0.1-0.1,0.1c-0.4,0.2-0.7,0.3-0.9,0.1
			c-0.3-0.2-0.4-0.5-0.5-1c0-0.6,0.2-1.2,0.7-1.6c0.6-0.5,1.3-0.8,2.1-0.9c0.6,0,1.2,0.3,1.6,0.8C27.9,40.5,28.1,41.2,28.1,41.8z"/>
		<path class="st0" d="M8.4,26.6c0.6,0.2,1.2,0,1.7-0.4c0.3-0.3,0.6-0.7,0.9-1c0.4-0.6,0.6-1.4,0.8-2.2c0.1-0.3,0.2-0.7,0.3-1.1
			c0.2-0.6,0.7-1,1.3-1.1c0.6-0.1,1.1,0.2,1.2,0.7c0.4,1.4,0.3,2.5-0.4,3.5c-0.6,0.8-1,1.6-1.3,2.5c-0.2,0.7-0.1,1.4,0.3,2
			c0.2,0.3,0.5,0.5,0.8,0.5c0.8,0.1,1.6-0.4,2-1.3c0.1-0.2,0.1-0.3,0.2-0.5c0.5-1.9,0.6-3.6,0.4-5.3c-0.3-1.7-1-3.4-2.2-5.1
			c-1-1.4-2.3-2.7-3.9-3.9c-0.4-0.3-0.9-0.5-1.3-0.7c-1-0.3-1.9,0-2.5,1c-0.3,0.5-0.4,1-0.3,1.5c0.1,0.4,0.4,0.8,0.9,1
			c0.5,0.2,1,0.3,1.4,0.3c0.3,0,0.5,0,0.8,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0.2,0.9,0.8c0.1,0.3,0.1,0.6,0.1,0.9
			c-0.1,1.4-0.5,2.6-1.4,3.6c-0.5,0.6-1.1,0.9-1.7,1c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.4-0.3-0.8-0.3-1.2c-0.1-0.2-0.1-0.5-0.1-0.7
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.6-0.4-1.2-0.7-1.6c-0.4-0.5-0.8-0.6-1.3-0.5c-0.4,0.1-0.7,0.3-1,0.5C2.8,19.7,2,21,1.5,22.7
			c-0.5,1.8-0.6,3.7-0.2,5.8c0.1,0.5,0.3,1,0.5,1.5C2.1,30.7,2.5,31,3.1,31c0.2,0,0.4,0,0.6-0.1c0.5-0.2,1-0.5,1.4-1.1
			c0.5-0.6,0.5-1.2,0-1.7c-0.2-0.3-0.5-0.5-0.9-0.5c-0.4-0.1-0.7-0.3-0.9-0.6c-0.9-1.8-0.7-3.7,0.7-5c0.3-0.2,0.5-0.3,0.8-0.3
			c0.3,0,0.5,0.2,0.7,0.5c0.2,0.3,0.4,0.7,0.6,1.1c0.1,0.3,0.3,0.6,0.4,0.9C6.6,24.5,6.8,25,7,25.4C7.3,26,7.8,26.5,8.4,26.6z"/>
		<path class="st0" d="M55.6,25c0.3-0.3,0.5-0.8,0.7-1.3c0.2-0.6,0-1.1-0.4-1.5c-0.5-0.6-1.2-0.7-2-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
			c-0.1,0.1-0.3,0.1-0.4,0.2c-0.6,0.2-1.2-0.1-1.4-0.8c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.3-0.1-0.4
			c-0.1-0.3-0.2-0.7-0.3-1c-0.3-0.9-0.9-1.3-1.6-1.3c0,0-0.1,0-0.1,0c-0.3,0-0.7,0-1,0c-1,0.2-1.6,1-1.5,2.2c0,0.3,0.1,0.6,0.2,0.9
			c0.1,0.6,0,1-0.4,1.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.8,0.6-1.2,1.5-1.2,2.6c0,0.7,0.1,1.4,0.5,2.1c0.3,0.7,0.3,1.4,0,2
			c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c-0.4,0.8-0.5,1.7-0.3,2.5
			c0.3,1.1,1.2,1.8,2.2,1.8c0,0,0.1,0,0.1,0c0.5,0,1-0.3,1.4-0.8c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3
			c0.7-0.8,1.7-0.9,2.5-0.2c0.2,0.2,0.4,0.3,0.5,0.5c0.5,0.5,1.1,0.9,1.9,1c1,0.3,1.9-0.2,2.3-1.1c0.7-1.6,0.6-3-0.2-4.4
			c-0.2-0.4-0.5-0.7-0.7-1c-0.3-0.4-0.4-0.9-0.2-1.5C54.5,26.6,55,25.8,55.6,25z M53.4,25.4c-0.1,0.4-0.2,0.9-0.3,1.3
			c-0.1,0.3-0.1,0.5-0.2,0.8c-0.2,0.7-0.3,1.7,0.3,2.7c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0,0.1,0.1,0.2
			c-0.3,0.4-0.6,0.6-1,0.7c-0.3,0.1-0.5,0-0.8-0.3c-0.3-0.3-0.5-0.6-0.7-0.8c-0.4-0.4-0.7-0.7-1.1-0.7c-0.3,0-0.6,0.1-1,0.3
			c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6
			c0-1.1,0-2,0.1-2.8c0.1-0.7,0.2-1.5,0.5-2.2c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0.2-0.4l0,0c0.5-0.7,1-1.4,1.7-1.9
			c0.2-0.1,0.4-0.2,0.6-0.3c0.2,0,0.3,0,0.5,0.2c0.3,0.2,0.9,0.6,2.1,0.8l0,0l0,0c0.3,0,0.6,0,0.7,0.2
			C53.4,24.9,53.4,25.2,53.4,25.4z"/>
		<path class="st0" d="M22.9,18.6c0.7,0.8,0.8,1.7,0.4,2.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,0.3
			c-0.2,0.4-0.3,0.8-0.4,1.2c-0.2,1.1,0.1,2,1,2.9c0.4,0.4,0.8,0.6,1.3,0.6c0.3,0,0.6-0.1,1-0.3c0.5-0.2,0.9-0.7,1.1-1.3
			c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.4-0.6,0.8-0.6c0.4-0.1,0.8,0,1.2,0.1c0.8,0.4,1.6,0.4,2.4,0.4
			c0,0,0,0,0,0c0.3,0,0.5,0,0.8-0.1c0.1,0,0.2,0,0.3-0.1l0,0c0.8-0.3,1.1-0.8,1.1-1.6c0-0.8-0.2-1.5-0.8-2c-0.4-0.3-0.7-0.5-1.1-0.4
			c-0.6,0.1-1.3,0.3-1.8,1c-0.3,0.4-0.6,0.7-1.1,0.8c-1.2,0.3-2.1,0-2.9-0.9c-1.3-1.5-1.9-3.1-2-4.9c0-1,0.2-1.9,0.7-2.6
			c0.4-0.6,1-0.8,1.6-0.6c0.6,0.2,1.2,0.5,1.6,1c0.4,0.5,0.7,1,0.7,1.7c0,0.2,0,0.5,0.1,0.7c0.1,1.1,0.8,1.8,1.7,1.8c0,0,0,0,0,0
			c0.6,0,1.2-0.4,1.5-0.9c0.3-0.5,0.4-1.1,0.2-1.7c-0.5-1.3-1.1-2.3-1.8-2.9c-0.6-0.6-1.3-1-2.2-1.4c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.5-0.3-0.8-0.9-1.1-1.7c-0.2-0.7-0.7-1.2-1.4-1.4C24.4,7.9,24,8,23.8,8.3c-0.6,0.6-0.8,1.4-0.7,2.2c0.1,0.5,0.3,0.9,0.5,1.2
			c0.3,0.6,0.3,0.9,0,1.3c-0.3,0.4-0.8,0.8-1.2,1.2c-0.5,0.4-0.7,0.9-0.7,1.6c0,0.5,0.1,1,0.4,1.4C22.2,17.8,22.5,18.2,22.9,18.6z"
			/>
		<path class="st0" d="M34.2,22.2c0.3,1.3,0.3,2.4-0.1,3.4c-0.3,1.1-0.6,2.3-0.9,3.4l-0.1,0.5c-0.1,0.6-0.2,1.2-0.1,1.8
			c0.1,1,0.8,1.6,1.7,1.5c0.7,0,1.1-0.5,1.1-1.2c0-0.6,0-1.1-0.1-1.6c-0.3-1.5-0.3-2.9-0.2-4.3c0-0.6,0.1-1,0.3-1.3
			c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.5-1,1.4-1.3,2.5-0.9l0,0c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.6-0.5,1.1-0.7,1.7
			c-0.4,1.8,0.2,3.8,0.6,5.2c0.2,0.7,0.4,1.3,0.4,1.7c0,0.7,0.4,1.3,0.9,1.7c0.3,0.2,0.7,0.3,1.1,0.3c0.3,0,0.6-0.1,0.9-0.2
			c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.6-0.7c0.4-0.7,0.3-1.5-0.3-2.1
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.4-2.3-4-2.5-4.7c-0.1-0.5,0-1,0.2-1.3c0.2-0.3,0.6-0.5,1.1-0.5
			c0,0,0,0,0,0c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0,0.9,0l0.1,0c0.3,0,0.7,0,1-0.2c1.8-0.9,2.6-2.8,2.1-4.8c-0.2-0.8-0.6-1.3-1.1-1.4
			c-0.3-0.1-0.7-0.1-1.2-0.1c-0.6,0-1.3,0.3-1.8,0.6l-0.1,0c-0.8,0.4-1.7,0.3-2.4-0.2c-0.4-0.3-0.8-0.7-1.1-1
			c-0.6-0.6-1.2-0.9-1.8-1.2c-0.6-0.2-1.3-0.4-2.1-0.2c-0.7,0.2-1.1,0.7-1.3,1.4c-0.2,1.1-0.1,2,0.3,2.9C33.7,20.1,34,21,34.2,22.2z
			 M36,17.1c0.2,0,0.3,0.1,0.4,0.2l0.1,0.1c0.3,0.3,0.6,0.6,0.9,0.9c0.7,0.9,1.6,1.2,2.6,1l0.3-0.1c0.3-0.1,0.7-0.2,1-0.2
			c0.2,0,0.4-0.1,0.6,0c0.3,0,0.6,0.2,0.7,0.6c0.1,0.4,0.1,0.7-0.2,1c-0.3,0.4-0.8,0.5-1.2,0.4c-0.9-0.2-1.7-0.4-2.5-0.6
			c-0.6-0.2-1.2-0.1-1.7,0.4c-0.6,0.5-0.8,0.5-1.3-0.2c-0.5-0.6-0.8-1.3-0.8-2.1l0,0c-0.1-0.6,0.2-1,0.7-1.3
			C35.7,17.1,35.9,17.1,36,17.1z"/>
		<path class="st0" d="M16.4,18.4c0.3,0.5,0.6,1,1.2,1.4c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.1,0.3,0.2,0.5,0.4c0.9,0.8,1.2,2.2,0.9,3.3
			c-0.3,1.2-0.8,2.4-1.6,3.5c-0.5,0.8-0.9,1.7-1.1,2.7c-0.3,1.2-0.2,2.1,0.1,3c0.2,0.4,0.4,0.9,0.9,1c0.1,0,0.3,0.1,0.4,0.1
			c0.4,0.1,0.6,0.2,0.7,0.2c0,0,0,0,0,0l0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c1,0.1,1.8-0.6,2.2-1.7c0.1-0.4,0.2-0.8,0.3-1.2
			l0-0.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.5-0.5-0.8-0.8-1.1c-0.3-0.4-0.4-0.8-0.5-1.3c-0.1-0.7,0-1.4,0.4-2.2c0.5-1.1,0.5-2.2,0.2-3.4
			c-0.2-0.8-0.5-1.5-0.7-2.1c-0.4-0.9-0.6-1.9-0.5-2.9c0-0.7,0-1.3-0.2-2c-0.1-0.6-0.5-1.1-0.9-1.4c-0.4-0.2-0.9-0.2-1.4,0.1
			c-1,0.6-1.5,1.9-1.3,3.2C16,17.5,16.2,17.9,16.4,18.4z"/>
	</g>
	<path class="st0" d="M55.2,16.8v0.5h-1v2.5h-0.6v-2.5h-1v-0.5H55.2z M58.4,19.8l-0.1-1.8c0-0.2,0-0.5,0-0.8h0
		c-0.1,0.3-0.2,0.6-0.3,0.9l-0.5,1.7h-0.6l-0.5-1.7c-0.1-0.2-0.1-0.6-0.2-0.8h0c0,0.3,0,0.6,0,0.8l-0.1,1.8h-0.6l0.2-3h0.9l0.5,1.5
		c0.1,0.2,0.1,0.4,0.2,0.8h0c0.1-0.3,0.1-0.5,0.2-0.7l0.5-1.5h0.9l0.2,3H58.4z"/>
</g>
</svg></a>
</div>
            <div class="logo-bar">
                <div class="tagline"><button class = "menu" ><a target = "_blank" href="https://www.instagram.com/microbar.us/"><i class="fa fa-instagram" style="font-size:36px; color:white;"></i></a></button></div>
                <div class="toggle"><button class = "menu" ><i class="fa fa-bars" style="font-size:36px;color:white;"></i></button></div>
            </div>
        </div>
        <div class="overlay">
            <div class="whitespace"></div>
            <div class="discover item">
                <div class="col-3"><p class = "discover-p">// &nbsp;&nbsp; discover</p></div>
                <div class="col-2">
                    <div class="link"><a href="index.html">home</a></div>
                    <div class="link"><a href="mainline.html">mainline</a></div>
                    <div class="link"><a href="quickstrike.html">quickstrike</a></div>
                    <div class="link"><a href="liveresin.html">live resin</a></div>
                    <div class="link"><a href="dual.html">dual</a></div>
                </div>
            </div>
             <div class="discover item">
                <div class="col-1"><p class = "discover-p" >// &nbsp;&nbsp; flavors</p></div>
                <div class="col-2">
                    <div class="link"><a href="strawberryfields.html">strawberry fields</a></div>
                    <div class="link"><a href="mangosunset.html">mango sunset</a></div>
                    <div class="link"><a href="blueberrykush.html">blueberry kush</a></div>
                    <div class="link"><a href="pinklemonade.html">pink lemonade</a></div>
                    <div class="link"><a href="watermelonmimosa.html">watermelon mimosa</a></div>
                    <div class="link"><a href="pinklychee.html">pink lychee</a></div>
                </div>
                  <div class="col-2">
                    <div class="link"><a href="peachdriver.html">peach driver</a></div>
                    <div class="link"><a href="orangetangie.html">orange tangie</a></div>
                    <div class="link"><a href="lemondiesel.html">lemon diesel</a></div>
                    <div class="link"><a href="limehaze.html">lime sorbet haze</a></div>
                    <div class="link"><a href="pineappleexpress.html">pineapple express</a></div>
                    <div class="link"><a href="apricotjelly.html">apricot jelly</a></div>
                </div>
            </div>
            <div class="connect item">
                <div class="col-3"><p class = "discover-p" >// &nbsp;&nbsp; connect</p></div>
                <div class="col-2">
                     <span class="link" ><a href="index.html#contact">contact</a></span>
                    <span class="discover-p" >&nbsp;/&nbsp;</span>
                    <span class="link" ><a href="index.html#map">find us</a></span>
                    <span class="discover-p" >&nbsp;/&nbsp;</span>
                    <span class="link" ><a target=”_blank” href="https://www.instagram.com/microbar.us/">instagram</a></span>
                </div>
            </div>
            <div class="legal item">
                <div class="col-3"><p class = "discover-p" >// &nbsp;&nbsp; legal</p></div>
                <div class="col-2">
                    <span class="link" ><a href="#">terms & conditions</a></span>
                    <span class="discover-p" >&nbsp;/&nbsp;</span>
                    <span class="link" ><a href="#">privacy policy</a></span>
                </div>
            </div>
        </div>
    </div>

    `

	}
}

customElements.define('my-header', MyHeader)
